<template>
  <div class="recharge-container">
    <header class="recharge-header">
      <img src="@/assets/back.png" alt="" class="back-img" @click="goBack">
      <h1>充值系统</h1>
    </header>
    <main class="recharge-content">
      <div class="recharge-content-top">
        <img src="@/assets/pay.png" alt="">
      </div>
      <div class="recharge-content-bottom">
        <!-- 修改：仅在获取支付链接成功后显示倒计时 -->
      <div class="countdown-container" >
        <div v-if="isPayUrlFetched">
          <img src="@/assets/time.png"  alt="">
          <p >请在<span class="red-color">{{ countdown }}</span> 秒内完成支付</p>
        </div>
        <div v-if="isFailed">
          <img src="@/assets/error.png"  alt="">
          <p >{{ tipMessage }}</p>
        </div>
        <div v-if="orderCompleted">
          <img src="@/assets/success.png"  alt="">
          <p >{{ tipMessage }}</p>
        </div>
        <div v-if="!isPayUrlFetched && !isFailed && !orderCompleted">
          <img src="@/assets/load.png"  class="loading-img" alt="">
          <p >{{ tips[status] }}<span class="red-color" v-if="status == 2">{{ countdown2 }}</span><span v-if="status == 2">秒</span></p>
        </div>
      </div>
      <div class="recharge-content-info">
        <div class="title">支付信息</div>
        <p class="recharge-content-p"><span>订单ID: </span>{{ orderId }}</p>
        <p class="recharge-content-p"><span>支付金额: </span>{{ orderAmount }}</p>
        <p class="recharge-content-p"><span>支付方式: </span>支付宝</p>
      </div>
      <!-- 修改：提示信息显示逻辑 -->
      
      <!-- <p v-if="isFailed" class="fetching-pay-url-tip">{{ tipMessage }}</p> -->
      <!-- <p v-if="ipInfo">当前公网 IP: {{ ipInfo.ip }}，省: {{ ipInfo.province }}，市: {{ ipInfo.city }}</p>  -->
      <!-- <p v-if="isFailed">获取支付链接失败</p>  -->
      <!-- <p v-if="orderCompleted" class="order-completed">订单完成</p> -->
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      orderId: this.$route.query.orderId,
      orderAmount: this.$route.query.orderAmount,
      countdown: 120,
      timer: null,
      timer2: null,
      countdown2: 14,
      status: 0,//0:订单匹配中，1:订单匹配成功，2:正在打开支付链接，3:网络延时即将打开，
      tips: ['订单匹配中','订单匹配成功','正在打开支付链接','网络有延时，即将打开'], // 
      retryCount: 0,
      tipMessage: '', // 修正错别字
      retryTimer: null,
      isFailed: false,
      ipInfo: null,
      orderCompleted: false,
      isPayUrlFetched: false // 新增：标志是否获取到支付链接
    };
  },
  async mounted() {
    this.startCountdown2();
    await this.getIpInfo().catch(error => {
      console.error('获取 IP 信息失败:', error);
      this.ipInfo = {
        ip: '',
        province: '',
        city: ''
      };
    });
    // 去掉原有的倒计时启动逻辑
    try {
      await this.getQrCode();
    } catch (error) {
      console.error('获取支付链接失败:', error);
      this.handleFailure();
    }
  },
  
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    startCountdown2() {
      this.timer2 = setInterval(() => {
        this.countdown2--;
        if(this.countdown2 == 9){
          this.status = 1;
        }
        if(this.countdown2 == 6){
          this.status = 2;
        }
        if (this.countdown2 <= 0) {
          this.status = 3;
          clearInterval(this.timer2);
        }
      }, 1000);
    },
    startCountdown() {
      // 仅在获取支付链接成功后开始倒计时
      this.timer = setInterval(() => {
        if (this.countdown > 0 && !this.isFailed && !this.orderCompleted) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    async getIpInfo() {
      // 保持原逻辑不变
      const apiUrls = [
        'https://page.tlbb.pw/api2/GetTest?id=1',
        'https://ipinfo.io/json',
        'http://ip-api.com/json/?fields=query,regionName,city',
        'https://ipv4.icanhazip.com'
      ];

      const headers = {
        'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3'
      };

      for (let i = 0; i < apiUrls.length; i++) {
        const apiUrl = apiUrls[i];
        try {
          const response = await axios.get(apiUrl, { headers });
          if (apiUrl === 'https://page.tlbb.pw/api2/GetTest?id=1') {
            this.ipInfo = {
              ip: response.data.vcResult,
              province: '',
              city: ''
            };
          } else if (apiUrl === 'https://ipinfo.io/json') {
            this.ipInfo = {
              ip: response.data.ip,
              province: response.data.region,
              city: response.data.city
            };
          } else if (apiUrl === 'http://ip-api.com/json/?fields=query,regionName,city') {
            this.ipInfo = {
              ip: response.data.query,
              province: response.data.regionName,
              city: response.data.city
            };
          } else if (apiUrl === 'https://ipv4.icanhazip.com') {
            this.ipInfo = {
              ip: response.data.trim(),
              province: null,
              city: null
            };
          }
          if (this.ipInfo.ip) {
            return;
          }
        } catch (error) {
          console.error(`使用 ${apiUrl} 获取 IP 信息失败:`, error);
        }
      }
      throw new Error('所有获取公网 IPv4 及地区信息的接口均失败');
    },
    async getQrCode() {
      let isPayUrlFound = false; // 新增标志位
      try {
          //const response = await axios.post('https://page.tlbb.pw/api/res/pay.order/check.order', {
          const response = await axios.post(' http://localhost:5010/api/res/pay.order/check.order', {   
          orderId: this.orderId,
          ip: this.ipInfo ? this.ipInfo.ip : null,
          province: this.ipInfo ? this.ipInfo.province : null,
          city: this.ipInfo ? this.ipInfo.city : null
        });
        console.log('API 响应:', response.data);
        const payUrl = response.data.data.payUrl;
        console.log('payUrl:', payUrl);
        const orderStatus = response.data.data.orderStatus;
        console.log('orderStatus:', orderStatus);
        if (orderStatus < 0 || orderStatus > 4) {
          this.handleFailure();
          return;
        }
  
        if (orderStatus === 4) {
          this.orderCompleted = true;
          this.tipMessage = '订单完成';
          clearInterval(this.timer);
          return;
        }
  
        if (payUrl && orderStatus === 3) {
          isPayUrlFound = true; // 标记已找到支付链接
          this.isPayUrlFetched = true; // 标记获取到支付链接
          this.startCountdown(); // 开始倒计时
          clearTimeout(this.retryTimer);
          console.log('获取支付链接成功:', payUrl);
          this.tipMessage = '请在2分钟内完成支付';
          window.open(payUrl, '_self');
        } else if (this.retryCount < 30) {
          this.retryCount++;
          this.retryTimer = setTimeout(() => {
            this.getQrCode();
          }, 2000);
        } else {
          if (!isPayUrlFound) { // 若未找到支付链接
            this.handleFailure();
          }
        }
      } catch (error) {
        console.error('获取支付链接失败:', error);
        if (this.retryCount < 30) {
          this.retryCount++;
          this.retryTimer = setTimeout(() => {
            this.getQrCode();
          }, 2000);
        } else {
          if (!isPayUrlFound) { // 若未找到支付链接
            this.handleFailure();
          }
        }
      }
    },
    handleFailure() {
      clearTimeout(this.retryTimer);
      clearInterval(this.timer);
      this.tipMessage = '获取支付链接失败';
      this.isFailed = true;
      this.isPayUrlFetched = false; // 确保标志位正确
    }
  }
};
</script>
<style scoped>
.back-img{
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 10px;
}
.red-color{
  color: #ff1a1a;
  font-weight: 700;
  margin:0 2px;
}
.loading-img{
  width: 2rem;
  height: 2rem;
  animation: rotate 2s linear infinite; /* 添加旋转动画 */
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.recharge-container {
  max-width: 640px;
  margin: 0 auto;
  /* font-family: 'Roboto', sans-serif; */
  background:#f7f7f7 ;
  height: 100vh;
  /* max-width: 800px;
  margin: 50px auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: #f4f6f9;  */
}

.recharge-header {
  position: relative;
  text-align: center;
  /* margin-bottom: 30px; */
  width: 100%;
  height: 44px;
  color: #fff;
  background:#3F9FFF;
  /* background-color: #0776e5; */
}

.recharge-header h1 {
  color: #fff; 
  font-size: 1rem;
  line-height: 44px;
}
.recharge-content {
  /* background: #fff; */
  /* height: calc(100vh - 12rem); */

}
.recharge-content-top {
  background: #3F9FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
/* .recharge-content-top img {
  width: 14rem;
  height: auto;
} */
@keyframes breathing {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.recharge-content-top img {
  width: 14rem;
  height: auto;
  animation: breathing 2s infinite;
}

.recharge-content-bottom{
  width: calc(100% - 2rem);
  box-shadow: 0px 3px 6px 0px #232B321A;
  background: #fff;
  margin: 0 auto;
  border-radius: 16px;
  position: relative;
  top: -1rem;
  padding: 1rem;
  text-align: left;
}
.recharge-content-info{
  margin:1rem;
}
.recharge-content-info .title{
  color: #333; 
  font-size: 0.9rem;
  margin-bottom: 10px;
  line-height: 1.6;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.recharge-content-info .title::before{
  content: '';
  display: inline-block;
  width: 4px;
  height: 16px;
  background: #3F9FFF;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
}
.recharge-content-p {
  color: #333; 
  font-size: 0.9rem;
  margin-bottom: 10px;
  line-height: 1.6;
}
.recharge-content-p span{
  display: inline-block;
  color: #666; 
  font-size: 0.9rem;
  margin-right: 0.5rem;
  width: 4rem;
  font-weight: 500;
}
.countdown-container>div{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.countdown-container p{
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}
.countdown-container img{
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.recharge-content p:last-child {
  margin-bottom: 0;
}

/* 修改后的倒计时样式 */
.countdown-active {
  color: #ff1a1a; 
  font-weight: 700;
  font-size: 24px; 

  /* animation: pulse 0.8s infinite; */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    color: #ff1a1a;
  }
  50% {
    transform: scale(1.2); /* 增大缩放比例 */
    color: #ff6666; /* 颜色过渡 */
  }
  100% {
    transform: scale(1);
    color: #ff1a1a;
  }
}

/* 订单完成样式 */
.order-completed {
  color: #00b300; 
  font-weight: 700;
  font-size: 24px; 
}

/* 新增：获取支付链接提示样式 */
.fetching-pay-url-tip {
  color: #ff9900; 
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
}

/* 定义闪烁动画 */
/* .blinking {
  animation: blink 2s infinite; 
} */

@keyframes blink {
  0% {
    opacity: 1;
    color: #ff9900;
  }
  50% {
    opacity: 0.2;
    color: #ffcc80; /* 颜色过渡 */
  }
  100% {
    opacity: 1;
    color: #ff9900;
  }
}
</style>
