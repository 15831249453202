<template>
  <div>
    <el-button>Hello World</el-button>
    <!-- 显示 IP 信息 -->
    <p v-if="ipInfo">当前公网 IP: {{ ipInfo.ip }}，省: {{ ipInfo.province }}，市: {{ ipInfo.city }}</p>
    <p v-if="isFailed">获取 IP 信息失败</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TestPage',
  data() {
    return {
      ipInfo: null,
      isFailed: false
    };
  },
  async mounted() {
    try {
      await this.getIpInfo();
    } catch (error) {
      console.error('获取 IP 信息失败:', error);
      this.isFailed = true;
    }
  },
  methods: {
    async getIpInfo() {
      const apiUrls = [
        'https://page.tlbb.pw/api2/GetTest?id=1', // 添加新接口并放在第一位
        'https://ipinfo.io/json',
        'http://ip-api.com/json/?fields=query,regionName,city',
        'https://ipv4.icanhazip.com'
      ];

      const headers = {
        'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3'
      };

      for (let i = 0; i < apiUrls.length; i++) {
        const apiUrl = apiUrls[i];
        try {
          const response = await axios.get(apiUrl, { headers });
          if (apiUrl === 'https://page.tlbb.pw/api2/GetTest?id=1') {
            this.ipInfo = {
              ip: response.data.vcResult,
              province: '',
              city: ''
            };
          } else if (apiUrl === 'https://ipinfo.io/json') {
            this.ipInfo = {
              ip: response.data.ip,
              province: response.data.region,
              city: response.data.city
            };
          } else if (apiUrl === 'http://ip-api.com/json/?fields=query,regionName,city') {
            this.ipInfo = {
              ip: response.data.query,
              province: response.data.regionName,
              city: response.data.city
            };
          } else if (apiUrl === 'https://ipv4.icanhazip.com') {
            this.ipInfo = {
              ip: response.data.trim(),
              province: null,
              city: null
            };
          }
          if (this.ipInfo.ip) {
            return; // 如果获取到有效的 IP 信息，停止尝试其他接口
          }
        } catch (error) {
          console.error(`使用 ${apiUrl} 获取 IP 信息失败:`, error);
        }
      }
      throw new Error('所有获取公网 IPv4 及地区信息的接口均失败');
    }
  }
};
</script>