<template>
    <div>
      <h1>支付结果</h1>
      <p>订单 ID: {{ orderId }}</p>
      <p>订单金额: {{ orderAmount }}</p>
      <p>订单状态: {{ orderStatus }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        orderId: this.$route.query.orderId,
        orderAmount: this.$route.query.orderAmount,
        orderStatus: this.$route.query.orderStatus
      };
    }
  };
  </script>