import Vue from 'vue';
import VueRouter from 'vue-router';
import RechargePage from '../components/RechargePage.vue';
import AmountInputPage from '../components/AmountInputPage.vue';
import ResultPage from '../components/ResultPage.vue';
import TestPage from '../components/TestPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/recharge',
    name: 'RechargePage',
    component: RechargePage
  },
  {
    path: '/amountinput',
    name: 'AmountInputPage',
    component: AmountInputPage
  },
  {
    path: '/result',
    name: 'ResultPage',
    component: ResultPage
  },
  {
    path: '/',
    name: 'TestPage',
    component: TestPage
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;